import { useField } from "formik";

export interface TextProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
  inputClassName?: string;
  onSearchClicked?: () => void;
}

export const Text: React.FC<TextProps> = ({
  label,
  error,
  ...props
}: TextProps) => {
  return (
    <div className="form-input-group w-full my-4">
      <div className="form-label mb-2 font-medium w-full">
        <p>{label}</p>
      </div>
      <div className="form-input">
        <input
          className={`${
            error ? "border-red-500" : "border-gray-300"
          } border p-1.5 w-full`}
          {...props}
        />
      </div>
      {error && (
        <div className="form-error text-red-500">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export interface FormikTextFieldGroupProps extends TextProps {
  name: string;
}

export const FormikText: React.FC<FormikTextFieldGroupProps> = ({
  name,
  ...props
}: FormikTextFieldGroupProps) => {
  const [field, meta] = useField(name);
  const error = (meta.touched && meta.error) || "";
  return <Text {...props} {...field} error={error} />;
};
