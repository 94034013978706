import { Empty, Image } from "antd";
import { useField } from "formik";
import { useEffect, useRef, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { Button } from "../Button/Button";

interface ImagePickerProps {
  images?: any;
  label: string;
  error?: string;
  isMultiple?: boolean;
  onChange?: (images: string[]) => void;
}

export const ImagePicker: React.FC<ImagePickerProps> = ({
  label,
  images: initialImage = [],
  error,
  onChange,
}: ImagePickerProps) => {
  const inputFile: any = useRef(null);
  const [images, setImages]: any = useState(initialImage);

  return (
    <div className="form-input-group  w-full my-4 ">
      <div className="form-label mb-2 font-medium w-full">
        <p>{label}</p>
      </div>
      <div
        className={`form-input border h-48 relative ${
          error && "border-red-500"
        }`}
      >
        <Button
          className="absolute top-2 right-2 border px-4 py-1"
          onClick={() => {
            inputFile.current && inputFile.current.click();
          }}
        >
          <span className="flex items-center">
            <input
              type="file"
              id="file"
              ref={inputFile}
              accept="image/png, image/gif, image/jpeg"
              multiple
              style={{ display: "none" }}
              onChange={({ target }) => {
                let i = Array.from(target.files || []).map((x) => ({
                  preview: URL.createObjectURL(x),
                  file: x,
                }));
                let newImageSets = [...images, ...i];
                setImages(newImageSets);
                onChange && onChange(newImageSets);
              }}
            />
            <FiPlus className="mr-1" />
            <p>Add New </p>
          </span>
        </Button>
        {images.length > 0 ? (
          <div className="flex items-center w-full h-full gap-4">
            <Image.PreviewGroup>
              {images.map((image: any, index:number) => (
                <Image className="w-32" key={index} src={image.preview} />
              ))}
            </Image.PreviewGroup>
          </div>
        ) : (
          <Empty
            className="flex items-center justify-center w-full h-full flex-col"
            description="No Images"
          />
        )}
      </div>
      {error && (
        <div className="form-error text-red-500">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export interface FormikImagePickerProps extends ImagePickerProps {
  name: string;
}

export const FormikImagePicker: React.FC<FormikImagePickerProps> = ({
  name,
  onChange,
  ...props
}: FormikImagePickerProps) => {
  const [field, meta] = useField(name);
  const error = (meta.touched && meta.error) || "";
  return (
    <ImagePicker {...props} {...field} error={error} onChange={onChange} />
  );
};
