import { Empty } from "antd";
import { useEffect, useState } from "react";
import "./App.css";
import { DynamicForms } from "./DynamicForm";

const getInpsections = (inspectionId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response: any = await fetch(
        `${process.env.REACT_APP_INSPECTION_API_ENDPOINT}/inspections/hash/${inspectionId}`
      );
      if (response.ok) {
        response = await response.json();
        resolve({ status: 200, inspection: response.data, message: "" });
      } else {
        if (response.status === 404) {
          reject({
            status: 404,
            message: "Inspection not Found",
            inspection: null,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  });
};

function App() {
  const [intialLoading, setIntialLoading] = useState(true);
  const [inspection, setInspection]: any = useState(null);
  const inspectionId = window.location.href.split("/").pop();
  const [loader, setLoader] = useState({ loading: false, message: "" });
  useEffect(() => {
    getInpsections(inspectionId || "")
      .then((response) => {
        setIntialLoading(false);
        setInspection(response);
      })
      .catch((errorResponse) => {
        setIntialLoading(false);
        console.log(errorResponse);
        setInspection(errorResponse);
      });
  }, []);
  if (intialLoading) {
    return (
      <div className="w-screen h-screen flex justify-center items-center text-3xl">
        Loading...
      </div>
    );
  }
  if (inspection && inspection.status !== 200) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Empty description={inspection.message} />
      </div>
    );
  } else if (
    inspection &&
    inspection.status === 200 &&
    inspection.inspection.status !== "submitted"
  ) {
    return (
      <div
        className={`w-screen h-screen flex justify-center  relative ${
          loader.loading ? "overflow-hidden" : "overflow-y-scroll"
        }`}
      >
        <div className="w-3/4 my-40">
          <DynamicForms
            components={inspection.inspection.templateComponent}
            id={inspection.inspection._id || ""}
            setLoader={(loader) => setLoader({ ...loader })}
          />
        </div>
        {loader.loading && (
          <div className="loader flex justify-center items-center w-screen h-screen bg-black/[0.7] absolute">
            <h3 className="text-lg text-white font-bold">{loader.message}</h3>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Empty description={"Form Submitted"} image={null} />
      </div>
    );
  }
}

export default App;
