import { useField } from "formik";
import Select, { Props as RSProps } from "react-select";

export interface SelectProps extends RSProps {
  label: string;
  error?: string;
  inputClassName?: string;
}

export const SingleSelect: React.FC<SelectProps> = ({
  label,
  error,
  ...props
}: SelectProps) => {
  return (
    <div className="form-input-group w-full my-4">
      <div className="form-label mb-2 font-medium w-full">
        <p>{label}</p>
      </div>
      <div className="form-input">
        {/* <input
          className={`${
            error ? "border-red-500" : "border-gray-300"
          } border p-1.5 w-full`}
          {...props}
        /> */}
        <Select {...props}/>
      </div>
      {error && (
        <div className="form-error text-red-500">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};




export interface FormikSelectProps extends SelectProps {
  name: string;
}



export const FormikSinglSelect: React.FC<FormikSelectProps> = ({
  name,
  onChange,
  ...props
}: FormikSelectProps) => {
  const [field, meta] = useField(name);
  const error = (meta.touched && meta.error) || "";
  return <SingleSelect {...props} {...field} error={error} onChange={onChange}/>;
};
