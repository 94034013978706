import { Divider } from "antd";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { Button } from "./atoms/Button/Button";
import { FormikImagePicker } from "./atoms/MultipleImagePicker/MultipleImagePicker";
import { FormikSinglSelect } from "./atoms/Select/Select";
import { FormikText } from "./atoms/Text/Text";
import { FormikTextArea } from "./atoms/TextArea/TextArea";
import * as Yup from "yup";
import { asyncForEach, uploadImage } from "./uploadImageHandler";

interface DynamicFormsProps {
  components: any;
  id: string;
  setLoader:(loader:any)=>void
}

const returnSubComponent = (
  formikProps: FormikHelpers<any>,
  component: any
  
) => {
  switch (component.type) {
    case "text":
    case "sign":
      return <FormikText name={component.key} label={component.title} />;
    case "datetime":
      return (
        <FormikText
          type={"datetime-local"}
          name={component.key}
          label={component.title}
        />
      );
    case "number":
      return (
        <FormikText
          type={"number"}
          name={component.key}
          label={component.title}
        />
      );
    case "textarea":
      return <FormikTextArea name={component.key} label={component.title} />;
    case "radio":
    case "select":
      return (
        <FormikSinglSelect
          options={component.options.map((x: any) => ({
            value: x.key,
            label: x.value,
          }))}
          onChange={(newValue) => {
            formikProps.setFieldValue(component.key, newValue);
          }}
          name={component.key}
          label={component.title}
        />
      );

    case "media/image/single":
    case "media/image/multi":
      return (
        <FormikImagePicker
          name={component.key}
          label={component.title}
          onChange={(images: any) => {
            formikProps.setFieldValue(component.key, images);
          }}
        />
      );
  }
};

const returnMainComponents = (
  formikProps: FormikHelpers<any>,
  component: any
) => {
  return (
    <div className="border my-5 p-3" key={component.key}>
      <Divider orientation="left" orientationMargin={"20px"}>
        <h2>{component.header}</h2>
      </Divider>
      <div>
        {component.components.map((subComponent: any) =>
          returnSubComponent(formikProps, subComponent)
        )}
      </div>
    </div>
  );
};

const returnFormInitialValues = (components: any[]) => {
  let initalValue = {};
  const imageIds: any = [];
  initalValue = components.reduce((previousValue, currentValue) => {
    currentValue.components.forEach((x: any) => {
      previousValue[`${x.key}`] = "";
      if (x.type === "media/image/single" || x.type === "media/image/multi") {
        imageIds.push(x.key);
      }
    });
    return previousValue;
  }, {});
  return { initalValue, imageIds };
};

const returnFormValidations = (components: any[]) => {
  // uploadImage("","").then(s => console.log(s))
  let validations = {};
  validations = components.reduce((previousValue, currentValue) => {
    currentValue.components.forEach((x: any) => {
      if (!x.isManditory) {
        return;
      }
      switch (x.type) {
        case "text":
        case "sign":
        case "textarea":
        case "number":
          return (previousValue[`${x.key}`] =
            Yup.string().required("Required"));
        case "datetime":
        case "radio":
        case "select":
        case "media/image/single":
        case "media/image/multi":
          return (previousValue[`${x.key}`] = Yup.mixed().required("Required"));
      }
    });
    return previousValue;
  }, {});
  return Yup.object(validations);
};

export const DynamicForms: React.FC<DynamicFormsProps> = ({
  components,
  id,
  setLoader 
}: DynamicFormsProps) => {
  const [{ initalValue, imageIds }] = useState(
    returnFormInitialValues(components)
  );
  const [loader, setLoading] = useState({
    loading: false,
    message: "",
  });

  useEffect(()=>{
    setLoader({...loader});
  },[loader])
  const [validations] = useState(returnFormValidations(components));
  return (
    <div>
      <Formik
        initialValues={initalValue}
        enableReinitialize
        validationSchema={validations}
        onSubmit={async (values: any, actions) => {
          try {
            setLoading({ loading: true, message: "Submitting your form..." });
            //Upload Images First:
            await asyncForEach(Object.keys(values), async (key) => {
              if (imageIds.includes(key)) {
                await asyncForEach(
                  values[key],
                  async (image, index: number) => {
                    if (image.preview.startsWith("blob:")) {
                      setLoading({
                        loading: true,
                        message: "uploading your image...",
                      });
                      const uploadResponse = await uploadImage(
                        image.file,
                        `${process.env.NODE_ENV}/${id}/`
                      );
                      values[key][index] = uploadResponse;
                    }
                  }
                );
              }
            });
            setLoading({ loading: true, message: "Submitting your form..." });
            const res = await fetch(
              `${process.env.REACT_APP_INSPECTION_API_ENDPOINT}/inspections/${id}`,
              {
                method: "PUT",
                headers: {
                  "Content-type": "application/json",
                },
                body: JSON.stringify({
                  templateData: values,
                  status: "submitted",
                }),
              }
            );
            setLoading({ loading: false, message: "Submitted" });
            window.location.reload();
          } catch (e) {
            console.log("error in form submit");
          }
        }}
      >
        {({ ...formikProps }) => (
          <Form>
            {components.map((component: any) =>
              returnMainComponents(formikProps, component)
            )}
            <div className="flex gap-3 flex-row-reverse mb-20">
              <Button
                type="submit"
                className="px-5 border py-2 font-bold text-base uppercase text-white"
                style={{ background: "#fb6db2" }}
              >
                Save
              </Button>
              <Button
                type="button"
                onClick={() => window.location.reload()}
                className="px-5 border py-2 font-bold text-base uppercase"
              >
                RESET
              </Button>
            </div>
          </Form>
        )}
      </Formik>
   
    </div>
  );
};
