import { default as ImageKit } from "imagekit-javascript";

export const uploadImage = (file: File, folder: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      
      var imagekit = new ImageKit({
        publicKey: "public_MvJRUqYY3sDnTggfFz3BxrkSYEM=",
        urlEndpoint: "https://ik.imagekit.io/zeebasoftwares",
        authenticationEndpoint: `${process.env.REACT_APP_IMAGE_API_ENDPOINT}/file/upload`,
      });

      const response = await imagekit.upload(
        { file, fileName: file.name, folder },
        {
          publicKey: "public_MvJRUqYY3sDnTggfFz3BxrkSYEM=",
          urlEndpoint: "https://ik.imagekit.io/zeebasoftwares",
          authenticationEndpoint: `${process.env.REACT_APP_IMAGE_API_ENDPOINT}/file/upload`,
        }
      );
      return resolve(response.filePath);
    } catch (e) {
      console.log(e)
      reject("Image Upload Error");
    }
  });
};




export const asyncForEach = async (array: Array<any>, callback: (item: any, index: number) => Promise<void>) => {
  for (let index = 0; index < array.length; index++) {
      await callback(array[index], index);
  }
}