export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
 
}

export const Button: React.FC<ButtonProps> = ({
  ...props
}: ButtonProps) => {
  return (
     <button {...props}/>
  );
};
